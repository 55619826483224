.traveler-form {
    border: 1px solid #f1f5f9;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    position: relative;
    z-index: 10;
  }
  
  .toggle-button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #3d5cb8;
    background: #f1f5f9;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button:hover {
    background: #e2e8f0;
  }
  
  .traveler-content {
    margin-top: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 100;
    animation: fadeInScale 0.3s ease-in-out;
    transform-origin: top;
    opacity: 0;
    transform: scaleY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
    padding: 1rem;
  }
  
  .traveler-content.show {
    opacity: 1;
    transform: scaleY(1);
  }
  
  .class-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .class-selector button {
    background-color: transparent;
    border: 1px solid #3d5cb8;
    color: #3d5cb8;
    font-weight: bold;
    cursor: pointer;
    padding: 0.4rem 1.5rem;
    border-radius: 20px;
    transition: all 0.3s ease;
  }
  
  .class-selector .active {
    background-color: #3d5cb8;
    color: white;
  }
  
  .traveler-selector {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .traveler-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .type-info {
    display: flex;
    flex-direction: column;
  }
  
  .type-title {
    font-size: 0.9rem;
    color: #333;
    margin: 0;
  }
    
  .traveler-type .age-range {
  font-size: 0.8rem;
  color: #6b7280;
  margin-top: 4px; /* Расстояние между названием категории и возрастом */
}
  
  .quantity-control {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 90px;
  }
  
  .quantity-control button {
    background-color: #e2e8f0;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    color: #3d5cb8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .quantity-control button:hover {
    background-color: #3d5cb8;
    color: white;
  }
  
  .quantity-control span {
    margin: 0;
    font-weight: bold;
    color: #0f172a;
    font-size: 1rem;
    width: 30px;
    text-align: center;
  }
  
  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scaleY(0);
    }
    to {
      opacity: 1;
      transform: scaleY(1);
    }
  }
  