.flight-offers {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
  }
  
  .flight-offer-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    gap: 12px;
    width: 90%;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.4s ease forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 1.2rem;
    color: #333;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .flight-details {
    display: flex;
    gap: 12px;
    padding: 12px 0;
  }
  
  .single-direction .direction {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 40px; /* Увеличиваем расстояние между элементами */
  }
  
  .direction {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .segment-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 24px; /* Увеличиваем промежуток между временем и локацией */
  }
  
  .time-location {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .time {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
  }
  
  .date {
    font-size: 0.9rem;
    color: #888;
  }
  
  .location {
    font-weight: bold;
    color: #555;
  }
  
  .arrow {
    font-size: 1.2rem;
    color: #888;
  }
  
  .duration {
    font-size: 0.9rem;
    color: #555;
    text-align: center;
  }
  
  .price-button {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #28a745;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-end;
    margin-top: 8px;
  }
  
  .price-button:hover {
    background-color: #218838;
  }
  
  /* Медиазапросы для мобильных устройств */
  @media (max-width: 768px) {
    .offer-header {
      font-size: 1rem;
    }
  
    .time {
      font-size: 1rem;
    }
  
    .date, .duration {
      font-size: 0.8rem;
    }
  
    .price-button {
      font-size: 0.9rem;
      padding: 6px 12px;
    }
  
    .flight-details {
      flex-direction: column;
      align-items: center;
    }
  }
  