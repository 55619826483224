.calendar {
    position: relative; /* Устанавливаем контейнер как позиционированный */
    width: 100%;
  }
  
  .calendar__input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .calendar__input:focus {
    border-color: #3d5cb8;
    outline: none;
  }

  .react-datepicker__month-container {
    float: none; /* Отменяем float */
    display: block; /* Устанавливаем блочный элемент для предотвращения конфликтов */
  }
  
  .calendar__datepicker {
    position: absolute;
    top: 100%; /* Календарь появляется сразу под инпутом */
    left: 50%; /* Центрируем по горизонтали относительно инпута */
    transform: translateX(-50%); /* Центрируем по горизонтали */
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out; /* Применяем анимацию */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Настройка стилей для react-datepicker */
  .react-datepicker__header {
    background-color: #3d5cb8; /* Основной цвет для заголовка */
    color: white;
    padding: 10px;
  }
  
  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: white;
    font-weight: bold;
  }
  
  .react-datepicker__day {
    color: #333;
    font-weight: 500;
  }
  .date-container{
    margin-top: 0px;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #3d5cb8; /* Основной цвет для выделения */
    color: white;
  }
  
  .react-datepicker__day--today {
    border: 1px solid #3d5cb8; /* Цвет рамки для сегодняшней даты */
  }
  
  .react-datepicker__day:hover {
    background-color: #e6ecfa; /* Цвет при наведении */
    color: #3d5cb8;
  }
  
  /* Стили для прошедших дат */
  .react-datepicker__day--disabled {
    color: #ccc; /* Бледный цвет для прошедших дат */
    cursor: not-allowed; /* Курсор "запрещено" */
    background-color: transparent; /* Убираем фон */
  }
  