.search-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .form__row {
    display: flex;
    gap: 16px;
  }
  
  .return-date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .no-return-label {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
    margin-top: 8px;
    cursor: pointer;
  }
  
  .no-return-checkbox {
    margin-right: 8px;
    cursor: pointer;
  }
  

  
  .btn:hover {
    background-color: #0056b3;
  }
  