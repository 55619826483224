.country-search {
  position: relative;
}



.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Легкая тень для дропдауна */
}

@keyframes fadeInDropdown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.city-item {
  padding: 12px 8px;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease;
}

.city-item:hover {
  background-color: #f8f8f8;
}

.city-item strong {
  display: block;
  color: #333;
  font-size: 1rem;
  padding: 4px 0;
  font-weight: 600;
}

.airport-list {
  padding-left: 16px;
  list-style-type: none;
  animation: fadeInAirports 0.2s ease forwards;
}

.airport-item {
  padding: 6px 0;
  cursor: pointer;
  color: #555;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.airport-item:hover {
  background-color: #f0f0f0;
  color: #333;
}

.airport-item::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #e0e0e0;
  transition: width 0.2s ease;
  z-index: -1;
}

.airport-item:hover::before {
  width: 100%;
}

@keyframes fadeInAirports {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
